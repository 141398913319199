import {Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  AddSourceToPatientCart,
  LoadPatientCart,
  RemoveSourcesFromPatientCart,
  UpdateSourcesInPatientCart,
} from './cart.actions';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PatientCartStateSelectors} from './patient-cart.state';
import {OrderApiService} from '@matchsource/api/orders';
import {ORDER_TYPE, OrderType, OrderTypeModel} from '@matchsource/models/order';
import {PatientCartItemKey, PatientCartItemModel} from '@matchsource/models/cart';
import {WorkupInProgressService, WorkupsInProgressState} from '@matchsource/store/workup';
import {uniq} from '@matchsource/utils';
import {CartApiService} from '@matchsource/api/cart';
import {FeatureService} from '@matchsource/feature-toggle';
import {FeatureToggleKey} from '@matchsource/core';

const orderComparator = (first: OrderTypeModel, second: OrderTypeModel) =>
  ORDER_TYPE[first.orderType].order - ORDER_TYPE[second.orderType].order;

export class PatientCartService {
  sourceIdList$: Observable<MsApp.Guid[]>;

  cartCordIdList$: Observable<MsApp.Guid[]>;

  cartDonorIdList$: Observable<MsApp.Guid[]>;

  availableToSelectCount$: Observable<number>;

  hasAvailableToSubmitOrdinaryCount$: Observable<number>;

  workupInProgressCount$: Observable<number>;

  count$: Observable<number>;

  size$: Observable<number>;

  isAwayNotificationShown = true;

  private get map() {
    return this.store.selectSnapshot(PatientCartStateSelectors.map(this.patientId));
  }

  get hasAvailableToSelect() {
    return this.store.selectSnapshot(PatientCartStateSelectors.hasAvailableToSelect(this.patientId));
  }

  get hasAvailableToSubmit() {
    return this.store.selectSnapshot(PatientCartStateSelectors.hasAvailableToSubmit(this.patientId));
  }

  get hasAvailableToSubmitOrdinaryCount() {
    return this.store.selectSnapshot(PatientCartStateSelectors.hasAvailableToSubmitOrdinaryCount(this.patientId));
  }

  get availableToSelectCount() {
    return this.store.selectSnapshot(PatientCartStateSelectors.availableToSelectCount(this.patientId));
  }

  get donors() {
    return this.store.selectSnapshot(PatientCartStateSelectors.donors(this.patientId)) || [];
  }

  get cords() {
    return this.store.selectSnapshot(PatientCartStateSelectors.cords(this.patientId)) || [];
  }

  get workupInProgressCount(): number {
    return this.store.selectSnapshot(PatientCartStateSelectors.workupInProgressCount(this.patientId));
  }

  get isSubsequentFeatureEnabled(): boolean {
    return this.featureService.enabled(FeatureToggleKey.SubsequentDonorRequest);
  }

  constructor(
    public readonly patientId: MsApp.Guid,
    private readonly store: Store,
    private readonly orderApi: OrderApiService,
    private readonly workupInProgressService: WorkupInProgressService,
    private readonly cartApiService: CartApiService,
    private readonly featureService: FeatureService
  ) {
    if (!this.isSubsequentFeatureEnabled) {
      const filtered = this.cartApiService.get(patientId).filter(data => data.formCode !== OrderType.Subsequent);
      this.cartApiService.delete(patientId);
      if (filtered.length > 0) {
        this.cartApiService.save(patientId, filtered);
      }
    }
    this.init();
  }

  private init() {
    const {patientId, store} = this;

    this.sourceIdList$ = combineLatest([
      store.select(PatientCartStateSelectors.sourceIdList(patientId)),
      this.workupInProgressService.sourceIdList$,
    ]).pipe(
      map(([cartSourceIdList, workupInProgressSourceIdList]) =>
        uniq([...cartSourceIdList, ...workupInProgressSourceIdList])
      )
    );

    this.cartCordIdList$ = combineLatest([
      store.select(PatientCartStateSelectors.cordIdList(patientId)),
      this.workupInProgressService.cordIdList$,
    ]).pipe(
      map(([cartCordIdList, workupInProgressCordIdList]) => uniq([...cartCordIdList, ...workupInProgressCordIdList]))
    );

    this.cartDonorIdList$ = combineLatest([
      store.select(PatientCartStateSelectors.donorIdList(patientId)),
      store.select(WorkupsInProgressState.donorIdList),
      this.workupInProgressService.donorIdList$,
    ]).pipe(
      map(([cartDonorIdList, workupInProgressLegacyDonorIdList, workupInProgressDonorIdList]) =>
        uniq([...cartDonorIdList, ...workupInProgressLegacyDonorIdList, ...workupInProgressDonorIdList])
      )
    );

    this.size$ = store.select(PatientCartStateSelectors.size(patientId));

    this.availableToSelectCount$ = this.store.select(PatientCartStateSelectors.availableToSelectCount(patientId));
    this.hasAvailableToSubmitOrdinaryCount$ = this.store.select(
      PatientCartStateSelectors.hasAvailableToSubmitOrdinaryCount(patientId)
    );
    this.workupInProgressCount$ = this.store.select(PatientCartStateSelectors.workupInProgressCount(patientId));

    this.count$ = combineLatest([
      this.availableToSelectCount$,
      this.hasAvailableToSubmitOrdinaryCount$,
      this.workupInProgressCount$,
    ]).pipe(map(counts => counts.reduce((count, itemCount) => count + (itemCount || 0), 0)));
  }

  async load() {
    await Promise.all([
      this.workupInProgressService.load(this.patientId),
      firstValueFrom(this.store.dispatch(new LoadPatientCart(this.patientId))),
    ]);
  }

  getDonorsAvailableOrders(sourceIds: MsApp.Guid[]) {
    return this.orderApi
      .getDonorAvailableOrderTypes(this.patientId, sourceIds)
      .pipe(map(item => item.sort(orderComparator)));
  }

  getCordsAvailableOrders(sourceIds: MsApp.Guid[]) {
    return this.orderApi
      .getCordAvailableOrderTypes(this.patientId, sourceIds)
      .pipe(map(item => item.sort(orderComparator)));
  }

  getBiobanksAvailableOrders(sourceIds: MsApp.Guid[]) {
    return this.orderApi
      .getCordAvailableOrderTypes(this.patientId, sourceIds)
      .pipe(map(item => item.sort(orderComparator)));
  }

  cordsOmidubicelCheck(cordIdList: MsApp.Guid[]): Observable<boolean> {
    return this.orderApi.cordsOmidubicelCheck(this.patientId, cordIdList);
  }

  /*
  The methods below are used for compatibility with AngularJS code which use cart service. It can be refactored when totally migrate from AngularJS.
   */
  get size() {
    return this.store.selectSnapshot(PatientCartStateSelectors.size(this.patientId));
  }

  isEmpty() {
    return this.size === 0;
  }

  isInCart(sourceId: MsApp.Guid): boolean {
    return sourceId in this.map;
  }

  get(sourceId: MsApp.Guid) {
    const {map: sourceMap} = this;

    return sourceId in sourceMap ? sourceMap[sourceId] : undefined;
  }

  add(sourceId: MsApp.Guid, data: Partial<PatientCartItemModel>) {
    this.store.dispatch(new AddSourceToPatientCart(this.patientId, sourceId, data));
  }

  update(items: (PatientCartItemKey & Partial<PatientCartItemModel>)[]) {
    this.store.dispatch(new UpdateSourcesInPatientCart(this.patientId, items));
  }

  remove(id: MsApp.Guid | MsApp.Guid[]) {
    this.store.dispatch(new RemoveSourcesFromPatientCart(this.patientId, id));
  }
}

@Injectable({
  providedIn: 'root',
})
export class PatientCartServiceFactory {
  constructor(
    private readonly store: Store,
    private readonly orderApiService: OrderApiService,
    private readonly workupInProgressService: WorkupInProgressService,
    private readonly cartApiService: CartApiService,
    private readonly featureService: FeatureService
  ) {}

  async create(patientId: MsApp.Guid, load = true) {
    const patientCart = new PatientCartService(
      patientId,
      this.store,
      this.orderApiService,
      this.workupInProgressService,
      this.cartApiService,
      this.featureService
    );

    if (load) {
      await patientCart.load();
    }

    return patientCart;
  }
}
